import type { SVGProps } from "react";

export const Open = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}>
			<circle
				cx="12"
				cy="12"
				r="2.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<path
				d="M21.25 12C21.25 13.2899 20.3747 14.5858 18.6852 15.5995C17.0106 16.6043 14.6489 17.25 12 17.25C9.35114 17.25 6.98935 16.6043 5.3148 15.5995C3.62532 14.5858 2.75 13.2899 2.75 12C2.75 10.7101 3.62532 9.41417 5.3148 8.40048C6.98935 7.39575 9.35114 6.75 12 6.75C14.6489 6.75 17.0106 7.39575 18.6852 8.40048C20.3747 9.41417 21.25 10.7101 21.25 12Z"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const Closed = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<circle
				cx="12"
				cy="12"
				r="2.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"></circle>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				d="M21.25 12c0 1.29-.875 2.586-2.565 3.6-1.674 1.004-4.036 1.65-6.685 1.65s-5.01-.646-6.685-1.65C3.625 14.586 2.75 13.29 2.75 12s.875-2.586 2.565-3.6C6.989 7.396 9.35 6.75 12 6.75s5.01.646 6.685 1.65c1.69 1.014 2.565 2.31 2.565 3.6z"></path>
		</svg>
	);
};
