import type { SVGProps } from "react";

export const Location = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<circle
				cx="12"
				cy="9"
				r="2.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"></circle>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				d="M17.25 9c0 .356-.093.88-.287 1.557-.191.665-.465 1.432-.795 2.256-.66 1.648-1.527 3.485-2.348 5.126a2.01 2.01 0 01-3.64 0c-.82-1.64-1.688-3.478-2.348-5.126a24.172 24.172 0 01-.795-2.256C6.843 9.88 6.75 9.356 6.75 9a5.25 5.25 0 0110.5 0z"></path>
		</svg>
	);
};
