import type { SVGProps } from "react";

export const Delete = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}>
			<path
				d="M6.80645 8.16129L7.63326 18.083C7.67646 18.6013 8.10972 19 8.62981 19H15.8218C16.3419 19 16.7752 18.6013 16.8183 18.083L17.6452 8.16129M5 8.16129C5 8.16129 11.2399 8.16129 19 8.16129M15.8387 7.70968L14.3291 5.4453C14.1437 5.1671 13.8314 5 13.4971 5H10.5029C10.1686 5 9.85634 5.1671 9.67088 5.4453L8.16129 7.70968"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
