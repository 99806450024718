import type { SVGProps } from "react";

type ChevronProps = SVGProps<SVGSVGElement> & {
	rotateanimation?: {
		trigger: boolean;
		deg: number;
	};
};

export const Chevron = (props: ChevronProps) => {
	return (
		<svg
			style={{
				transition: "transform 0.3s ease",
				transform: props.rotateanimation?.trigger
					? `rotate(${props.rotateanimation?.deg}deg)`
					: undefined,
			}}
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			fill="none"
			viewBox="0 0 15 15"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M4.5 6l3 3 3-3"></path>
		</svg>
	);
};
