export const Retailer = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24">
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4.667 6.273V3h14.666v3.273L22 9H2l2.667-2.727zM4 13v7h16v-7M2 9v1.5A2.5 2.5 0 004.5 13v0A2.5 2.5 0 007 10.5V9M7 9v1.5A2.5 2.5 0 009.5 13v0a2.5 2.5 0 002.5-2.5V9M12 9v1.5a2.5 2.5 0 002.5 2.5v0a2.5 2.5 0 002.5-2.5V9M17 9v1.5a2.5 2.5 0 002.5 2.5v0a2.5 2.5 0 002.5-2.5V9"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M10 20v-5h4v5"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M5 6h14"></path>
		</svg>
	);
};
