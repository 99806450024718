import type { SVGProps } from "react";

export const Waterproof = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M6 27h28"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				d="M22.646 12.969c.983 1.967-.447 4.281-2.646 4.281-2.2 0-3.63-2.314-2.646-4.281L20 7.677l2.646 5.292zM30.468 19.092c1.023 1.873-.334 4.158-2.468 4.158s-3.49-2.285-2.468-4.158L28 14.566l2.468 4.526zM14.469 19.092c1.022 1.873-.335 4.158-2.469 4.158s-3.49-2.285-2.468-4.158L12 14.566l2.469 4.526z"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M8 30l-1.5 1.5M11.5 30L10 31.5M15 30l-1.5 1.5M18.5 30L17 31.5M22 30l-1.5 1.5M25.5 30L24 31.5M29 30l-1.5 1.5M32.5 30L31 31.5"></path>
		</svg>
	);
};
