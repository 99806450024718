import type { SVGProps } from "react";

export const Exit = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		{...props}>
		<path
			stroke="var(--svg-color, currentColor)"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="m17 16 4-4m0 0-4-4m4 4H7m6 4v1a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1"></path>
	</svg>
);
