import type { SVGProps } from "react";

export const Favourite = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}>
			<path
				d="M11.4645 6.25677C9.98563 4.58108 7.58796 4.58108 6.10913 6.25677C4.63029 7.93246 4.63029 10.6493 6.10913 12.325L12 19L17.8909 12.325C19.3697 10.6493 19.3697 7.93246 17.8909 6.25677C16.412 4.58108 14.0144 4.58108 12.5355 6.25677L12 6.86359L11.4645 6.25677Z"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
