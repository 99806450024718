import type { SVGProps } from "react";

export const Windresistant = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M7 27h28M9 30l-1.5 1.5M12.5 30L11 31.5M16 30l-1.5 1.5M19.5 30L18 31.5M23 30l-1.5 1.5M26.5 30L25 31.5M30 30l-1.5 1.5M33.5 30L32 31.5M12 8.649l6.601 6.6a2.26 2.26 0 002.327.541v0a2.259 2.259 0 001.409-1.409l.147-.43a1.528 1.528 0 00-.366-1.575v0M6.09 14l3.99 3.99c.496.497.724 1.202.612 1.895v0a2.185 2.185 0 01-1.807 1.807v0a2.185 2.185 0 01-1.895-.612l-.163-.163"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M9 11l11.317 11.755a3 3 0 004.323 0l4.36-4.53"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M25.5 17.5H30v4"></path>
		</svg>
	);
};
