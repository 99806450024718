import type { SVGProps } from "react";

export const Minus = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			fill="none"
			viewBox="0 0 15 15"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				d="M5 7.5h5"></path>
		</svg>
	);
};

export const Minus24 = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			stroke="var(--svg-color, currentColor)"
			strokeLinecap="round"
			strokeWidth={1.5}
			d="M7 12h10"
		/>
	</svg>
);
