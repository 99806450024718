import type { SVGProps } from "react";

export const CartIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinejoin="bevel"
				strokeWidth="1.5"
				d="M5.75 9c0-.69.56-1.25 1.25-1.25h10c.69 0 1.25.56 1.25 1.25v9c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V9z"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="bevel"
				strokeWidth="1.5"
				d="M9 10.5V7a3 3 0 116 0v3.5"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M9 15h6"></path>
		</svg>
	);
};
