import { env } from "@/env.mjs";

import {
	buildSelectionFetchers,
	buildSelectionHooks,
	createCentraClient,
	type DefaultSelection,
} from "@frend-digital/centra/client";

export const centraClientFetchers = buildSelectionFetchers({
	apiUrl: env.NEXT_PUBLIC_CENTRA_CHECKOUT,
});

export const centraClientAPI = createCentraClient(env.NEXT_PUBLIC_CENTRA_CHECKOUT);

export const centraClient = buildSelectionHooks({
	fetchers: centraClientFetchers,
});

export const {
	useSelection,
	useAddToCart,
	useSetQuantity,
	useSelectionStatus,
	useCentraCheckoutScript,
	useSelectionKey,
	useToken,
	useLoggedIn,
	useUser,
	useSignUp,
	useLogin,
	useResetPassword,
	useChangePassword,
	useSubscribeNewsletter,
	useUpdateUser,
	useLogout,
	useSetSelection,
	useResetPasswordEmail,
} = centraClient;

export type SelectionItem = NonNullable<DefaultSelection["selection"]["items"]>[number];

export type Selection = DefaultSelection;
