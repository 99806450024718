import type { SVGProps } from "react";

export const Info = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			fill="none"
			viewBox="0 0 15 15"
			{...props}>
			<circle
				cx="7.5"
				cy="7.5"
				r="6.5"
				stroke="var(--svg-color, currentColor)"
				fillOpacity="0.1"></circle>
			<path
				stroke="var(--svg-color, currentColor)"
				d="M7 5.63c0-.372.228-.63.636-.63.39 0 .618.258.618.63 0 .36-.228.606-.618.606C7.228 6.236 7 5.99 7 5.63zm.108 1.026h1.02v3.36h-1.02v-3.36z"></path>
		</svg>
	);
};
