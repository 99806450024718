import type { SVGProps } from "react";

export const Menu = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}>
			<path
				d="M5 6H19"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5 11.75H19"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5 17.5H19"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};
