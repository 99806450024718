import { classed, clsx } from "@frend-digital/ui";
import styles from "./index.module.css";

export const TextBase = classed.p({
	base: styles.root!,
	variants: {
		variant: {
			h1: styles.h1!,
			h2: styles.h2!,
			h3: styles.h3!,
			h4: styles.h4!,
			h5: styles.h5!,
			display1: styles.display1!,
			display2: styles.display2!,
			display3: styles.display3!,
			utility1: clsx(styles.utility1!, styles.utility),
			utility2: clsx(styles.utility2!, styles.utility),
			utility3: clsx(styles.utility3!, styles.utility),
			utility4: clsx(styles.utility4!, styles.utility),
			utility5: clsx(styles.utility5!, styles.utility),
			utility6: clsx(styles.utility6!, styles.utility),
			body: styles.body!,
			body2: styles.body2!,
			body3: styles.body3!,
			none: styles.none!,
		},
		mdVariant: {
			h1: styles.md_h1!,
			h2: styles.md_h2!,
			h3: styles.md!,
			h4: styles.md_h4!,
			h5: styles.md_h5!,
			display1: styles.md_display1!,
			display2: styles.md_display2!,
			display3: styles.md_display3!,
			utility1: clsx(styles.md_utility1!, styles.utility),
			utility2: clsx(styles.md_utility2!, styles.utility),
			utility3: clsx(styles.md_utility3!, styles.utility),
			utility4: clsx(styles.md_utility4!, styles.utility),
			utility5: clsx(styles.md_utility5!, styles.utility),
			utility6: clsx(styles.md_utility6!, styles.utility),
			body: styles.md_body!,
			body2: styles.md_body2!,
			body3: styles.md_body3!,
			none: styles.none!,
		},
		bold: {
			true: styles.bold!,
		},
		uppercase: {
			true: styles.uppercase!,
		},
		maxWidth: {
			content: styles.maxWidthContent!,
		},
		mx: {
			auto: styles.mxAuto!,
		},
		center: {
			true: styles.center!,
		},
		block: {
			true: styles.block!,
		},
		underline: {
			true: styles.underline!,
		},
		weight: {
			500: styles.w500!,
			600: styles.w600!,
			400: styles.w400!,
		},
		color: {
			primary: styles.colorPrimary!,
			secondary: styles.colorSecondary!,
			white: styles.colorWhite!,
			grey: styles.colorGrey!,
			greige: styles.colorGreige!,
			red: styles.colorRed!,
			"primary-dark-blue-06": styles.colorDarkBlue06!,
			"primary-dark-blue-08": styles.colorDarkBlue08!,
		},
		type: {
			error: styles.error!,
			success: styles.success!,
		},
	},
	defaultVariants: {
		variant: "body",
	},
});

type TextBaseProps = React.ComponentProps<typeof TextBase>;

export type TextVariantProps = TextBaseProps["variant"];

type TextProps = Omit<TextBaseProps, "mdVariant" | "variant"> & {
	variant?:
		| TextBaseProps["variant"]
		| {
				base: TextBaseProps["variant"];
				md: TextBaseProps["mdVariant"];
		  };
};

export const Text = ({ variant, ...rest }: TextProps) => {
	const variantProps =
		typeof variant === "object"
			? {
					variant: variant.base,
					mdVariant: variant.md,
				}
			: {
					variant,
				};

	return <TextBase {...rest} {...variantProps} />;
};
