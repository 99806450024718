import type { SVGProps } from "react";

export const Account = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<circle
				cx="12"
				cy="8"
				r="3.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"></circle>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M5.755 19.01c.025-1.71.192-2.94.935-3.787.726-.827 2.182-1.473 5.31-1.473 3.128 0 4.584.646 5.31 1.473.743.846.91 2.078.935 3.787a.241.241 0 01-.245.24H6a.241.241 0 01-.245-.24z"></path>
		</svg>
	);
};
