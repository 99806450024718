import type { SVGProps } from "react";

export const Shipping = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4 6.667v10.666l3.556 1.186L12 20M4 6.667l8 2.666M4 6.667L12 4l8 2.667M12 20V9.333M12 20l8-2.667V6.667m-8 2.666L16 8m4-1.333L16 8m0 0v5.778M16 8l-4-1.333"></path>
		</svg>
	);
};
