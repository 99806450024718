import type { SVGProps } from "react";

export const Jacket = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}>
			<path
				d="M9.36364 9.15385V17.3182C9.36364 18.247 8.61066 19 7.68182 19V19C6.75298 19 6 18.247 6 17.3182V8.60521C6 7.81597 6.46415 7.10053 7.18486 6.77886L9.25189 5.8563C9.78989 5.61618 10.1364 5.08212 10.1364 4.49296V4.49296C10.1364 3.66842 10.8048 3 11.6293 3H13.3707C14.1952 3 14.8636 3.66842 14.8636 4.49296V4.49296C14.8636 5.08212 15.2101 5.61618 15.7481 5.8563L17.8151 6.77886C18.5359 7.10053 19 7.81597 19 8.60521V17.6136C19 18.3793 18.3793 19 17.6136 19V19C16.848 19 16.2273 18.3793 16.2273 17.6136V9.15385"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M8 19V19C8 20.1046 8.89543 21 10 21H15C16.1046 21 17 20.1046 17 19V19"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<path
				d="M12.5 21V7.5"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M10 5.5L11.0858 6.58579C11.8668 7.36684 13.1332 7.36683 13.9142 6.58579L15 5.5"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};
