import type { SVGProps } from "react";

export const Arrow24 = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M12 16l4.5-4.5m0 0L12 7m4.5 4.5H7"></path>
		</svg>
	);
};

export const Arrow32 = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			viewBox="0 0 32 32"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M16.421 24L24 16.5m0 0L16.421 9M24 16.5H8"></path>
		</svg>
	);
};
