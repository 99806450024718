import type { SVGProps } from "react";

export const Light = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M8.064 31.306l15.5-15.5M10.793 20.148l1.414 7.071 7.07 1.414L29.3 18.613a2 2 0 00.585-1.415v-5.657a2 2 0 00-2-2h-5.656a2 2 0 00-1.415.586l-10.02 10.021zM25.642 22.27h-8.485"></path>
		</svg>
	);
};
