import type { SVGProps } from "react";

export const Packable = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			{...props}>
			<path
				d="M7.56943 32.2181L11.4326 28.3549M11.4326 28.3549L11.4568 31.4696M11.4326 28.3549L9.87529 28.3429L8.31793 28.3308"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.4306 7.97424L28.5674 11.8375M28.5674 11.8375L28.5432 8.72274M28.5674 11.8375L30.1247 11.8495L31.6821 11.8616"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.56943 7.97424L11.4326 11.8375M11.4326 11.8375L11.4568 8.72274M11.4326 11.8375L9.87529 11.8495L8.31793 11.8616"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.4306 32.2181L28.5674 28.3549M28.5674 28.3549L28.5432 31.4696M28.5674 28.3549L30.1247 28.3429L31.6821 28.3308"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="13.75"
				y="14.75"
				width="11.5"
				height="11.5"
				rx="1.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="16"
				y1="10.25"
				x2="19"
				y2="10.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="21"
				y1="10.25"
				x2="24"
				y2="10.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="21"
				y1="30.25"
				x2="24"
				y2="30.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="16"
				y1="30.25"
				x2="19"
				y2="30.25"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="9.25"
				y1="19"
				x2="9.25"
				y2="16"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="9.25"
				y1="24"
				x2="9.25"
				y2="21"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="30.25"
				y1="19"
				x2="30.25"
				y2="16"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
			<line
				x1="30.25"
				y1="24"
				x2="30.25"
				y2="21"
				stroke="var(--svg-color, currentColor)"
				strokeWidth="1.5"
			/>
		</svg>
	);
};
