import type { SVGProps } from "react";

export const CustomerService = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M2.75 8A4.25 4.25 0 017 3.75h10A4.25 4.25 0 0121.25 8v6A4.25 4.25 0 0117 18.25H3a.25.25 0 01-.25-.25V8z"></path>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M7 8h10M7 11h6M7 14h8"></path>
		</svg>
	);
};
