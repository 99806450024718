import type { SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			fill="none"
			viewBox="0 0 15 15"
			{...props}>
			<path
				stroke="var(--svg-color, currentColor)"
				strokeLinecap="round"
				d="M5 5l5 5M5 10l5-5"></path>
		</svg>
	);
};

export const Close24xLarge = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			stroke="#0C0F27"
			strokeLinecap="round"
			strokeWidth={1.5}
			d="M18 6 6 18M6 6l12 12"
		/>
	</svg>
);
