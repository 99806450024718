import { LoadingDots } from "@/components/icons";
import { clsx } from "@frend-digital/ui";
import Link from "next/link";
import { type ComponentProps } from "react";
import styles from "./index.module.css";

export const buttonColors = {
	darkblue: styles.darkblue,
	greige: styles.greige,
	white: styles.white,
	yellow: styles.yellow,
	inactive: styles.inactive,
	secondaryBlue: styles.secondaryBlue,
} as const;

export type ButtonColor = keyof typeof buttonColors;

const buttonSizes = {
	small: styles.small,
	medium: styles.medium,
	large: styles.large,
	xLarge: styles.xLarge,
} as const;

type ButtonSize =
	| keyof typeof buttonSizes
	| {
			base: keyof typeof buttonSizes;
			md: keyof typeof buttonSizes;
	  };

const buttonVariants = {
	default: "",
	category: styles.category!,
	unstyled: styles.unstyled!,
};

type ButtonVariant = keyof typeof buttonVariants;

const buttonRounded = {
	md: styles.roundedMd,
	lg: styles.roundedLg,
	full: styles.roundedFull,
} as const;

type Rounded = keyof typeof buttonRounded;

type ButtonBaseProps = {
	isLoading?: boolean;
	color?: ButtonColor;
	size?: ButtonSize;
	variant?: ButtonVariant;
	rounded?: Rounded;
	preview?: boolean;
	active?: boolean;
	disabled?: boolean;
};

type ButtonButtonProps = Omit<ComponentProps<"button">, "color"> &
	ButtonBaseProps & {
		href?: undefined;
	};
type ButtonLinkProps = ComponentProps<typeof Link> &
	ButtonBaseProps & {
		href: string;
	};

type ButtonProps = (ButtonButtonProps | ButtonLinkProps) & { prefetch?: boolean };

export const Button = ({
	color = "secondaryBlue",
	variant = "default",
	rounded = "full",
	size = "medium",
	children,
	disabled,
	isLoading,
	href,
	// eslint-disable-next-line no-unused-vars
	preview: _,
	active,
	className,
	onClick,
	prefetch,
	...rest
}: ButtonProps) => {
	if (variant === "unstyled")
		return (
			<UnstyledButton
				onClick={onClick}
				{...rest}
				href={href}
				disabled={disabled}
				className={className}>
				{children}
			</UnstyledButton>
		);

	const colorClass = buttonColors[color as keyof typeof buttonColors];

	let sizeClass: string;
	if (typeof size === "object") {
		sizeClass = clsx(buttonSizes[size.base], styles[`md-${size.md}`]);
	} else {
		sizeClass = buttonSizes[size]!;
	}

	const variantClass = buttonVariants[variant];
	const roundedClass = buttonRounded[rounded];

	const classes = clsx(
		styles.root,
		colorClass,
		variantClass,
		sizeClass,
		roundedClass,
		isLoading && styles.isLoading,
		active && styles.active,
		className,
		disabled && styles.disabled,
	);

	const innerButton = (
		<>
			<span className={styles.innerButton}>{children}</span>
			{isLoading && <LoadingDots className={styles.spinner} />}
		</>
	);

	if (href && !disabled) {
		return (
			<Link
				onClick={onClick}
				className={classes}
				prefetch={prefetch}
				{...(rest as ButtonLinkProps)}
				href={href}>
				{innerButton}
			</Link>
		);
	}

	return (
		<button
			className={classes}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onClick={disabled ? undefined : (onClick as any)}
			disabled={disabled}
			{...(rest as ButtonButtonProps)}>
			{innerButton}
		</button>
	);
};

export const UnstyledButton = ({
	children,
	className,
	href,
	onClick,
	prefetch,
	...rest
}: Omit<ButtonProps, "variant" | "rounded" | "size" | "color">) => {
	if (href) {
		return (
			<Link
				prefetch={prefetch}
				className={clsx(styles.unstyled, className)}
				{...(rest as ButtonLinkProps)}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onClick={rest.disabled ? undefined : (onClick as any)}
				href={href}>
				{children}
			</Link>
		);
	}

	return (
		<button
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onClick={rest.disabled ? undefined : (onClick as any)}
			className={clsx(styles.unstyled, className)}
			{...(rest as ButtonButtonProps)}>
			{children}
		</button>
	);
};
